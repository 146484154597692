<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            is_readonly
              ? `${$t("application.See {type} adapter config", {
                  type: "Saml2"
                })}：${name}`
              : this.type === "create"
              ? `${$t("application.Create {type} adapter config", {
                  type: "SAML2"
                })}`
              : `${$t("application.Edit {type} adapter config", {
                  type: "SAML2"
                })}：${name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        SAML2应用列表项
      </v-alert>
      <v-row>
        <v-col cols="3">
          <v-subheader> 应用ID</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            v-model="service_id"
            :disabled="type !== 'create'"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="$t('ID Info')"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 显示名称</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :auto-grow="true"
            rows="1"
            v-model="name"
            :readonly="is_readonly"
            :disabled="is_readonly"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 身份</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-select
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            v-model="identity_claim"
            :items="identity_claims"
            item-text="display_name"
            item-value="name"
            required
            :rules="rules.notNullRules"
            @change="identityChange(identity_claim)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-8 mb-4 pl-4">
        对接选项 &nbsp; <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> audience</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : 'audience'"
            v-model="audience"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span> nameIdFormat</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-0">
          <v-select
            :readonly="is_readonly"
            :disabled="is_readonly"
            v-model="nameIdFormat"
            :items="nameIdFormats"
            persistent-hint
            :hint="is_readonly ? '' : ''"
            @change="formatSel(nameIdFormat)"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span> consumer_url</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :rules="is_readonly ? [] : rules.isurl"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : '断言消费服务地址'"
            v-model="consumer_url"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="0">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 属性名</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : 'issuer'"
            v-model="attributeName"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="0">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 类型</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-select
            :readonly="is_readonly"
            :disabled="is_readonly"
            v-model="curType"
            :items="types"
            persistent-hint
            :hint="is_readonly ? '' : ''"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="0">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 值</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : ''"
            v-model="val"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 断言签名</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-switch
            :readonly="is_readonly"
            :disabled="is_readonly"
            v-model="require_sign"
            inset
            @change="isSign(require_sign)"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 是否加密</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-switch
            :readonly="is_readonly"
            :disabled="is_readonly"
            v-model="require_encrypt"
            inset
            @change="isEncrypt(require_encrypt)"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row v-if="require_encrypt">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 证书</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : ''"
            v-model="cert"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="0">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> Binding</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-select
            v-model="service_options.binding_method"
            :items="binding_methods"
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            persistent-hint
            :hint="is_readonly ? '' : 'Bingding'"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row
        v-if="type === 'create' && credential_options"
        align="center"
        class="mt-8 mb-4 pl-4"
      >
        密钥参数 &nbsp; <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row
        v-if="type !== 'create' && credential_options"
        align="center"
        class="mt-8 mb-4 pl-4"
      >
        密钥详情 &nbsp; <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row v-if="type === 'create' && credential_options">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 通用名称</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : '通用名称'"
            v-model="credential_options.common_name"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="type === 'create' && credential_options">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 实体ID</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : '实体ID'"
            v-model="credential_options.entity_id"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="type === 'create' && credential_options">
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span> 签名密钥算法</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-0">
          <v-select
            :readonly="is_readonly"
            :disabled="is_readonly"
            v-model="credential_options.signing_key_spec"
            :items="signing_key_specs"
            persistent-hint
            :hint="is_readonly ? '' : '签名密钥算法'"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="type !== 'create' && credentials" class="align-center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 通用名称</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <span style="color:rgba(0, 0, 0, 0.65)">{{
            credentials.common_name
          }}</span>
        </v-col>
      </v-row>
      <v-row v-if="type !== 'create' && credentials" class="align-center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 实体ID</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <span style="color:rgba(0, 0, 0, 0.65)">{{
            credentials.entity_id
          }}</span>
        </v-col>
      </v-row>
      <v-row v-if="type !== 'create' && credentials" class="align-center">
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span> 签名密钥算法</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-0">
          <span style="color:rgba(0, 0, 0, 0.65)">{{
            credentials.signing.key_spec
          }}</span>
        </v-col>
      </v-row>
      <v-row v-if="type !== 'create' && credentials" class="align-center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 导出操作</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-btn
            class="ml-3"
            depressed
            color="primary"
            @click="exportSecretkey"
          >
            <v-icon class="pr-1">mdi-database-export-outline</v-icon>
            导出密钥
          </v-btn>
          <v-btn
            class="ml-3"
            depressed
            color="primary"
            @click="exportCertificate"
          >
            <v-icon class="pr-1">mdi-database-export-outline</v-icon>
            导出证书
          </v-btn>
          <v-btn class="ml-3" depressed color="primary" @click="exportData">
            <v-icon class="pr-1">mdi-database-export-outline</v-icon>
            导出元数据
          </v-btn>
          <v-textarea
            v-if="0"
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : '证书信息'"
            v-model="credentials.signing.certificate"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row
        align="center"
        class="mt-8 mb-4 pl-4"
        v-if="is_readonly && endpoints"
      >
        服务URL &nbsp; <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row v-if="is_readonly && endpoints" class="align-center">
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span> 单点登出地址</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-0">
          <span style="color:rgba(0, 0, 0, 0.65)">{{
            endpoints.PROVIDE_IDENTITY
          }}</span>
        </v-col>
      </v-row>

      <v-row v-if="!is_readonly">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="type === 'create' ? create() : save()"
            >{{ type === "create" ? "创建" : "提交" }}</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import { modify_application, create_application } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "SAML2Editor",
  props: ["source_id", "is_readonly", "type", "allDatas"],
  data() {
    let isTrueUrl = value => {
      let reg = /(http|https):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };
    let isRight = value => {
      const reg = /^[0-9a-zA-Z]+$/;
      return (
        value && reg.test(value) && value.length >= 3 && value.length <= 20
      );
    };
    return {
      valid: true,
      name: "",
      service_id: "saml2",
      service_type: "",
      identity_claim: { display_name: "IAM用户ID(默认)" },
      identity_claims: [],
      audience: "",
      nameIdFormat: "persistent",
      nameIdFormats: ["persistent", "transient"],
      format: "",
      attributeName: "",
      curType: "CONSTANT",
      types: ["CONSTANT", "EXPRESSION"],
      val: "",
      cert: "",
      require_sign: false,
      require_encrypt: false,
      consumer_url: "",
      // service_options: {
      //   // signing_algorithm: "RSA3072"
      // },
      credential_options: {
        signing_key_spec: "RSA3072"
      },
      credentials: {
        signing: {
          key_spec: null
        }
      },
      endpoints: {},
      signing_key_specs: [
        "RSA2048",
        "RSA3072",
        "RSA4096",
        "EC256",
        "EC384",
        "EC512"
      ],
      binding_methods: ["GET", "POST"],
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isRight: [v => isRight(v) || "3-20个字符，只能包含数字和字母"],
        isurl: [v => isTrueUrl(v) || "请输入正确的URL"]
      }
    };
  },
  created() {
    this.fetchClaims();
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    formatSel(formatSel) {
      this.nameIdFormat = formatSel;
    },
    isSign(sign) {
      this.require_sign = sign;
    },
    isEncrypt(encrypt) {
      this.require_encrypt = encrypt;
    },
    identityChange(identity) {
      this.identity_claim = {
        display_name: identity
      };
    },
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          new_name: this.name,
          identity_claim:
            this.identity_claim.display_name === "IAM用户ID(默认)" ||
            this.identity_claim === "IAM用户ID(默认)"
              ? null
              : this.identity_claim.display_name,
          new_options: {
            consumer_url: this.consumer_url,
            assertion: {
              audience: this.audience,
              nameid: {
                format: this.nameIdFormat,
                value: this.identity_claim
              },
              require_sign: this.require_sign,
              require_encrypt: this.require_encrypt
            },
            recipient: {
              cert: this.cert,
              entity_id: this.credential_options.entity_id
            }
          }, //严格对应后端的service_options ,
          new_credentials: {}
        };
        const promise = modify_application(this.source_id, payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "应用更新成功";
          })
          .catch(({ code, message }) => {
            throw `应用修改失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          });
        this.$snackbar.delegate(promise);
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        let payload = {
          name: this.name,
          service_id: this.service_id,
          identity_claim:
            this.identity_claim.display_name === "IAM用户ID(默认)" ||
            this.identity_claim === "IAM用户ID(默认)"
              ? null
              : this.identity_claim.display_name,
          service_type: "SAML2",
          service_options: {
            consumer_url: this.consumer_url,
            assertion: {
              audience: this.audience,
              nameid: {
                format: this.nameIdFormat,
                value: this.identity_claim
              },
              require_sign: this.require_sign,
              require_encrypt: this.require_encrypt
            },
            recipient: {
              cert: this.cert,
              entity_id: this.credential_options.entity_id
            }
          }, //严格对应后端的service_options
          credential_options: this.credential_options //严格对应后端的service_options
        };

        create_application(payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            this.$router.push({ path: `/applications/list` });
            return "应用创建成功";
          })
          .catch(({ code, message }) => {
            throw `应用创建失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    fetchData() {
      let {
        name,
        service_options,
        service_type,
        service_id,
        identity_claim,
        credentials,
        endpoints
      } = this.allDatas;
      this.name = name;
      this.service_id = service_id;
      this.service_type = service_type;
      this.endpoints = endpoints;
      this.identity_claim = {
        display_name: identity_claim ? identity_claim : "IAM用户ID(默认)"
      };

      if (credentials) {
        if (credentials.signing) {
          this.credentials = credentials;
        }
      }

      if (service_options) {
        this.consumer_url = service_options.consumer_url;
        if (service_options.assertion) {
          let {
            audience,
            nameid,
            require_encrypt,
            require_sign
          } = service_options.assertion;
          this.audience = audience;
          this.nameIdFormat = nameid.format;
          this.require_encrypt = require_encrypt;
          this.require_sign = require_sign;
        }

        if (service_options.recipient) {
          this.cert = service_options.recipient.cert;
        }
      }
    },
    fetchClaims() {
      this.$http
        .get(`api/attribute/account/list`)
        .delegateTo(api_request)
        .then(data => {
          this.identity_claims = data.attributes.map(item => {
            item.display_name = `${item.display_name}(${item.name})`;
            return item;
          });
          this.identity_claims.unshift("IAM用户ID(默认)");
        })
        .catch(({ code, message }) => {
          throw `获取身份数据失败：${this.$t(
            "api." + code
          )}, 额外信息: ${"api." + JSON.stringify(message)}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    exportSecretkey() {
      const blob = new Blob([this.credentials.signing.private_key], {
        type: "text/plain;charset=utf-8"
      });
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = `${this.name}密钥.pem`;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    },
    exportCertificate() {
      const blob = new Blob([this.credentials.signing.certificate], {
        type: "text/plain;charset=utf-8"
      });
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = `${this.name}证书.pem`;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    },
    exportData() {
      window.location.href = this.endpoints.WELLKNOWN_INFO_PAGE;
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          // this.reset();
          this.service_type = "saml2";
        }
      }
    }
  },
  watch: {
    show_sheet(v) {
      if (v === false) {
        this.$refs.form.reset();
      } else {
        this.fetchData();
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.65);
}

/deep/ .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
