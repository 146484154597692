<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            is_readonly
              ? `${$t("application.See {type} adapter config", {
                  type: "阿里云RAM-角色SSO"
                })}：${name}`
              : this.type === "create"
              ? `${$t("application.Create {type} adapter config", {
                  type: "阿里云RAM-角色SSO"
                })}`
              : `${$t("application.Edit {type} adapter config", {
                  type: "阿里云RAM-角色SSO"
                })}：${name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        阿里云RAM-角色SSO应用列表项
      </v-alert>
      <v-row>
        <v-col cols="3">
          <v-subheader> 应用ID</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            v-model="service_id"
            :disabled="type !== 'create'"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="$t('ID Info')"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 显示名称</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :auto-grow="true"
            rows="1"
            v-model="name"
            :readonly="is_readonly"
            :disabled="is_readonly"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-8 mb-4 pl-4">
        对接选项 &nbsp; <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 主账户ID</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="
              is_readonly ? '' : '阿里云账号 ID，可以在阿里云个人信息页找到'
            "
            v-model="aliyun_id"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader> <span class="red--text">*</span> 角色ID </v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : '阿里云角色ID'"
            v-model="aliyun_role"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader>
            <span class="red--text">*</span> 身份提供商ID
          </v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : '阿里云身份提供商ID'"
            v-model="aliyun_provider"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 用户别名</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-select
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            v-model="claim"
            :items="identity_claims"
            item-text="display_name"
            item-value="name"
            required
            :rules="rules.notNullRules"
            @change="identityChange(claim)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row
        v-if="type !== 'create' && credential_options"
        align="center"
        class="mt-8 mb-4 pl-4"
      >
        密钥详情 &nbsp; <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row v-if="type !== 'create' && credentials" class="align-center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 通用名称</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <span style="color:rgba(0, 0, 0, 0.65)">{{
            credentials.common_name
          }}</span>
        </v-col>
      </v-row>
      <v-row v-if="type !== 'create' && credentials" class="align-center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 实体ID</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <span style="color:rgba(0, 0, 0, 0.65)">{{
            credentials.entity_id
          }}</span>
        </v-col>
      </v-row>
      <v-row v-if="type !== 'create' && credentials" class="align-center">
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span> 签名密钥算法</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-0">
          <span style="color:rgba(0, 0, 0, 0.65)">{{
            credentials.signing.key_spec
          }}</span>
        </v-col>
      </v-row>

      <v-row v-if="type !== 'create' && credentials" class="align-center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 导出操作</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-btn
            class="ml-3"
            depressed
            color="primary"
            @click="exportSecretkey"
          >
            <v-icon class="pr-1">mdi-database-export-outline</v-icon>
            导出密钥
          </v-btn>
          <v-btn
            class="ml-3"
            depressed
            color="primary"
            @click="exportCertificate"
          >
            <v-icon class="pr-1">mdi-database-export-outline</v-icon>
            导出证书
          </v-btn>
          <v-btn class="ml-3" depressed color="primary" @click="exportData">
            <v-icon class="pr-1">mdi-database-export-outline</v-icon>
            导出元数据
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        align="center"
        class="mt-8 mb-4 pl-4"
        v-if="is_readonly && endpoints"
      >
        服务URL &nbsp; <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row v-if="is_readonly && endpoints" class="align-center">
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span> 单点登出地址</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-0">
          <span style="color:rgba(0, 0, 0, 0.65)">{{
            endpoints.PROVIDE_IDENTITY
          }}</span>
        </v-col>
      </v-row>

      <v-row v-if="!is_readonly">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="type === 'create' ? create() : save()"
            >{{ type === "create" ? "创建" : "提交" }}</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import { modify_application, create_application } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "Saml2AliyunRoleEditor",
  props: ["source_id", "is_readonly", "type", "allDatas"],
  data() {
    let isTrueUrl = value => {
      let reg = /(http|https):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };
    let isRight = value => {
      const reg = /^[0-9a-zA-Z]+$/;
      return (
        value && reg.test(value) && value.length >= 3 && value.length <= 20
      );
    };
    return {
      valid: true,
      name: "",
      service_id: "aliyunramrole",
      service_type: "",
      identity_claims: [],
      claim: null,
      aliyun_id: null,
      aliyun_role: null,
      aliyun_provider: null,
      credential_options: {
        signing_key_spec: "RSA3072"
      },
      endpoints: {},
      credentials: {
        signing: {
          key_spec: null
        }
      },
      signing_key_specs: [
        "RSA2048",
        "RSA3072",
        "RSA4096",
        "EC256",
        "EC384",
        "EC512"
      ],
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isRight: [v => isRight(v) || "3-20个字符，只能包含数字和字母"],
        isurl: [v => isTrueUrl(v) || "请输入正确的URL"]
      }
    };
  },
  async created() {
    await this.fetchClaims();
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    identityChange(identity) {
      this.claim = identity;
    },
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          new_name: this.name,
          new_options: {
            aliyun_id: this.aliyun_id,
            aliyun_role: this.aliyun_role,
            aliyun_provider: this.aliyun_provider,
            claim: this.claim
          }
        };
        const promise = modify_application(this.source_id, payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "应用更新成功";
          })
          .catch(({ code, message }) => {
            throw `应用修改失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          });
        this.$snackbar.delegate(promise);
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        let payload = {
          name: this.name,
          service_id: this.service_id,
          service_type: "ALIYUN_RAM_ROLE",
          service_options: {
            aliyun_id: this.aliyun_id,
            aliyun_role: this.aliyun_role,
            aliyun_provider: this.aliyun_provider,
            claim: this.claim
          }
        };

        create_application(payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            this.$router.push({ path: `/applications/list` });
            return "应用创建成功";
          })
          .catch(({ code, message }) => {
            throw `应用创建失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    fetchData() {
      let {
        name,
        service_options,
        service_type,
        service_id,
        endpoints,
        credentials
      } = this.allDatas;
      this.name = name;
      this.service_id = service_id;
      this.service_type = service_type;
      this.endpoints = endpoints;
      this.credentials = credentials;
      if (service_options) {
        let {
          aliyun_id,
          aliyun_role,
          aliyun_provider,
          claim
        } = service_options;
        this.aliyun_id = aliyun_id;
        this.aliyun_role = aliyun_role;
        this.aliyun_provider = aliyun_provider;
        this.claim = claim;
      }

      if (credentials) {
        if (credentials.signing) {
          this.credentials = {
            common_name: credentials.common_name,
            entity_id: credentials.entity_id,
            signing: {
              key_spec: credentials.signing.key_spec
            }
          };
        }
      }
    },
    fetchClaims() {
      this.$http
        .get(`api/attribute/account/list`)
        .delegateTo(api_request)
        .then(data => {
          this.identity_claims = data.attributes
            .filter(item => item.unique === true)
            .map(item => {
              item.display_name = `${item.display_name}(${item.name})`;
              return item;
            });
          if (this.type === "create") {
            this.claim = this.identity_claims.find(
              item => item.name === "login_name"
            ).name;
          }
        })
        .catch(({ code, message }) => {
          throw `获取身份数据失败：${this.$t(
            "api." + code
          )}, 额外信息: ${"api." + JSON.stringify(message)}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    exportSecretkey() {
      const blob = new Blob([this.credentials.signing.private_key], {
        type: "text/plain;charset=utf-8"
      });
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = `${this.name}密钥.pem`;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    },
    exportCertificate() {
      const blob = new Blob([this.credentials.signing.certificate], {
        type: "text/plain;charset=utf-8"
      });
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = `${this.name}证书.pem`;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    },
    exportData() {
      window.location.href = this.endpoints.WELLKNOWN_INFO_PAGE;
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          // this.reset();
        }
      }
    }
  },
  watch: {
    show_sheet(v) {
      if (v === false) {
        this.$refs.form.reset();
      }
    },
    allDatas() {
      this.fetchData();
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.65);
}

/deep/ .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
