<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            is_readonly
              ? `${$t("application.See {type} adapter config", {
                  type: "OIDC"
                })}：${name}`
              : this.type === "create"
              ? `${$t("application.Create {type} adapter config", {
                  type: "OIDC"
                })}`
              : `${$t("application.Edit {type} adapter config", {
                  type: "OIDC"
                })}：${name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        OIDC应用列表项
      </v-alert>
      <v-row>
        <v-col cols="3">
          <v-subheader> 应用ID</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            v-model="service_id"
            :disabled="type !== 'create'"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="$t('ID Info')"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 显示名称</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :auto-grow="true"
            rows="1"
            v-model="name"
            :readonly="is_readonly"
            :disabled="is_readonly"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 身份</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            readonly="readonly"
            disabled="disabled"
            :auto-grow="true"
            rows="1"
            v-model="identity_claim"
            :items="identity_claims"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-8 mb-4 pl-4">
        对接选项 &nbsp; <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span> service_options</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-0">
          <v-select
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            v-model="identity_claim"
            :items="identity_claims"
            item-text="display_name"
            item-value="name"
            required
            :rules="rules.notNullRules"
            @change="identityChange(identity_claim)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-8 mb-4 pl-4">
        密钥参数 &nbsp; <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span> credential_options</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :rules="is_readonly ? [] : rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
            height="auto"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="is_readonly ? '' : 'credential_options'"
            v-model="credential_options"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="!is_readonly">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="
              type === 'create'
                ? create(service_options)
                : save(service_options)
            "
            >{{ type === "create" ? "创建" : "提交" }}</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import { modify_application, create_application } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "OIDCEditor",
  props: ["source_id", "is_readonly", "type", "allDatas"],
  data() {
    let isTrueUrl = value => {
      let reg = /(http|https):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };
    let isRight = value => {
      const reg = /^[0-9a-zA-Z]+$/;
      return (
        value && reg.test(value) && value.length >= 3 && value.length <= 20
      );
    };
    return {
      valid: true,
      name: "",
      service_id: "oidc",
      service_type: "",
      identity_claim: { display_name: "IAM用户ID(默认)" },
      identity_claims: [],
      service_options: {},
      credential_options: "",
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isRight: [v => isRight(v) || "3-20个字符，只能包含数字和字母"],
        isurl: [v => isTrueUrl(v) || "请输入正确的URL"]
      }
    };
  },
  created() {
    this.fetchClaims();
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    identityChange(identity) {
      this.identity_claim = {
        display_name: identity
      };
    },
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          new_name: this.name,
          identity_claim: this.identity_claim,
          new_options: this.service_options,
          new_credentials: {}
        };
        const promise = modify_application(this.$route.params.id, payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "应用更新成功";
          })
          .catch(({ code, message }) => {
            throw `应用修改失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          });
        this.$snackbar.delegate(promise);
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        let payload = {
          name: this.name,
          service_id: this.service_id,
          service_type: "OIDC",
          service_options: this.service_options, //严格对应后端的service_options
          credential_options: this.credential_options //严格对应后端的service_options
        };

        create_application(payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            this.$router.push({ path: `/applications/list` });
            return "应用创建成功";
          })
          .catch(({ code, message }) => {
            throw `应用创建失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    fetchData() {
      let { name, service_options, service_type, service_id } = this.allDatas;
      this.name = name;
      this.service_options = service_options;
      this.service_id = service_id;
      this.service_type = service_type;
    },
    fetchClaims() {
      this.$http
        .get(`api/attribute/account/list`)
        .delegateTo(api_request)
        .then(data => {
          this.identity_claims = data.attributes.map(item => {
            item.display_name = `${item.display_name}(${item.name})`;
            return item;
          });
          this.identity_claims.unshift("IAM用户ID(默认)");
        })
        .catch(({ code, message }) => {
          throw `获取身份数据失败：${this.$t(
            "api." + code
          )}, 额外信息: ${"api." + JSON.stringify(message)}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          // this.reset();
          this.service_type = "oidc";
        }
      }
    }
  },
  watch: {
    show_sheet(v) {
      if (v === false) {
        this.$refs.form.reset();
      }
    },
    allDatas() {
      this.fetchData();
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.65);
}

/deep/ .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
